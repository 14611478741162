<template>
  <div class="breadcrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'LandingPage' }">
        <router-link class="breadcrumbLink" :to="{ name: 'LandingPage' }">
          {{ $t("pageTitle.ToeflList") }}
        </router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :key="breadcrumb.name"
        v-for="breadcrumb in breadcrumbList"
      >
        <router-link class="breadcrumbLink" :to="routeTo(breadcrumb)">
          {{ breadcrumb.text || $t(`pageTitle.${breadcrumb.name}`) }}
        </router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item v-if="this.$slots.lastPage">
        <slot name="lastPage"></slot>
      </el-breadcrumb-item>
      <slot name="restPage" />
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  props: ["customBreadcrumbList"],
  data() {
    return { breadcrumbList: [] };
  },
  mixins: [],
  watch: {
    customBreadcrumbList() {
      this.updateBreadcrumb();
    },
    $route() {
      this.updateBreadcrumb();
    }
  },
  created() {
    this.updateBreadcrumb();
  },
  methods: {
    routeTo(breadcrumb) {
      if (breadcrumb.path) {
        return { path: breadcrumb.path };
      }
      return { name: breadcrumb.name };
    },
    updateBreadcrumb() {
      this.breadcrumbList = this.filterRole(
        this.customBreadcrumbList || this.$route.meta.breadcrumb
      );
    },
    filterRole(breadcrumbList) {
      // if (this.isRoleAdmin()) return breadcrumbList;
      return (breadcrumbList || []).filter(
        breadcrumb => breadcrumb.name !== "adminHome"
      );
    }
  }
};
</script>

<style scoped>
.breadcrumb {
  margin: 20px 0 0 0;
}

.breadcrumbLink {
  text-decoration: none;
  color: #000 !important;
}

@media screen and (max-width: 736px) {
  ::v-deep .el-breadcrumb {
    font-size: 12px;
  }
  ::v-deep .el-breadcrumb__separator {
    margin: 0;
  }
}
</style>
