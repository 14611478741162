<template>
  <div class="container">
    <h4>{{ $t("userProfile.Subscription") }}</h4>
    <hr />
    <el-card
      v-for="(subscription, index) in subscriptions"
      :key="index"
      class="mb-3"
    >
      <el-collapse accordion>
        <el-collapse-item>
          <template slot="title">
            <h6 class="text-info" style="margin:0">
              <div>
                <span
                  v-if="
                    subscription.package.recurring.interval === 'month' &&
                      subscription.package.recurring.interval_count === 1
                  "
                  ><i class="fas fa-crown"></i> Pro
                  {{ $t("Pro.Auto-renew", { count: 1, month: "month" })
                  }}<span style="color:black">$20 USD</span></span
                >
                <span
                  v-if="
                    subscription.package.recurring.interval === 'month' &&
                      subscription.package.recurring.interval_count === 3
                  "
                  ><i class="fas fa-crown"></i> Pro
                  {{ $t("Pro.Auto-renew", { count: 3, month: "months" })
                  }}<span style="color:black">$50 USD</span></span
                >
                <span
                  v-if="
                    subscription.package.recurring.interval === 'month' &&
                      subscription.package.recurring.interval_count === 6
                  "
                  ><i class="fas fa-crown"></i> Pro
                  {{ $t("Pro.Auto-renew", { count: 6, month: "months" })
                  }}<span style="color:black">$88 USD</span></span
                >
              </div>
              <div class="pro-status">
                <el-tag
                  type="success"
                  v-if="subscription.cancel_at_period_end === 0"
                  >{{ $t("Pro.Subscribed") }}</el-tag
                >
                <el-tag
                  type="info"
                  v-if="subscription.cancel_at_period_end === 1"
                >
                  {{ $t("Pro.Canceled") }}
                </el-tag>
                <small class="pl-4">
                  {{ $t("Pro.Manage") }}
                </small>
              </div>
            </h6>
          </template>
          <div>
            <div>
              <!-- <span v-if="getIsPro" class="proIcon">
                <i class="fas fa-crown" />
              </span> -->
              <span v-if="getProfile.first_name">
                Hi, {{ getProfile.first_name }}:</span
              >
              <span v-else>Hi, {{ getProfile.username }}:</span>
            </div>
            <p>{{ $t("Pro.Thanks for being a Pro member!") }}</p>
            <div
              class="pro-date"
              v-if="subscription.cancel_at_period_end === 0"
            >
              <span>
                {{ $t("Pro.Next billing date") }}
                {{ instant.formatGoogleTime(subscription.current_period_end) }}
              </span>
              <el-button
                type="text"
                size="small"
                @click="unsubscribe(subscription.id)"
              >
                {{ $t("Pro.Cancel subscription") }}
              </el-button>
            </div>
            <div
              class="pro-date"
              v-if="subscription.cancel_at_period_end === 1"
            >
              <span></span>
              <el-button
                type="success"
                size="small"
                @click="
                  getPro(
                    subscription.package.recurring.interval,
                    subscription.package.recurring.interval_count
                  )
                "
              >
                {{ $t("Pro.Re-subscribe") }}
              </el-button>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import Plans from "@/apis/plans";
import { instant, user } from "@ivy-way/material";
export default {
  metaInfo() {
    return {
      title: "Plan"
    };
  },
  data() {
    return {
      subscriptions: []
    };
  },
  components: {},
  computed: {
    ...mapGetters("user", ["token", "getProfile", "getIsPro"]),
    instant() {
      return instant;
    },
    userMethods() {
      return user;
    }
  },
  async created() {
    this.getSubscriptions();
  },
  methods: {
    async getSubscriptions() {
      const res = await Plans.getSubscriptions();
      this.subscriptions = res.subscriptions;
    },
    unsubscribe(id) {
      this.$confirm(
        this.$t("Pro.You ll be canceling your subscription"),
        this.$t("Pro.Warning"),
        {
          confirmButtonText: this.$t("Pro.Yes"),
          cancelButtonText: this.$t("Pro.No"),
          type: "warning"
        }
      ).then(async () => {
        await Plans.unsubscribe(id);
        this.$message({
          type: "success",
          message: "Success!"
        });
        this.$router.go(0);
      });
    },
    async getPro(interval, intervalCount) {
      const loading = this.$loading({
        lock: true,
        text: "Redirecting to payment, please wait.",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      const res = await Plans.subscribePro({
        interval: interval,
        interval_count: intervalCount,
        success_url: "https://testadmit.com/plans/setting",
        cancel_url: "https://testadmit.com/plans/setting"
      });
      window.location.href = res.url;
      // loading.close();
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  max-width: 768px;
}
::v-deep .el-card__body {
  padding: 0;
  padding-left: 20px;
  padding-right: 10px;
}
.pro-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.text-info {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #409eff !important;
}
.pro-status {
  display: flex;
  align-items: center;
  padding-right: 0.2rem;
}

@media screen and (max-width: 1220px) {
  .text-info {
    font-size: 80%;
  }
}
</style>
